<template>
    <uw-content title="工单模板" padding="10px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.drawerCreate.Open()">新建工单模板</el-button>
        </template>

        <!-- 表格 -->
        <uw-table :show-header="false" :show-footer="false">
            <template slot="data">
                <vxe-table ref="xTable" height="100%" :loading="loading" :data="table">

                    <!-- 模板名称 -->
                    <vxe-column width="min-200" title="模板名称" field="name"></vxe-column>

                    <!-- 模板状态 -->
                    <vxe-column width="100" title="模板状态" align="center">
                        <template v-slot="{ row }">
                            <vxe-switch v-model="row.state" @change="TableUpdateState(row.id)" size="mini" open-label="启用" :open-value="1" :close-value="0" close-label="禁用"></vxe-switch>
                        </template>
                    </vxe-column>

                    <!-- 创建人 -->
                    <vxe-column width="100" title="创建人">
                        <template v-slot="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" :src="row.create_user?.avatar"> {{ row.create_user?.name[0] }} </el-avatar>
                                {{  row.create_user?.name }}
                            </div>
                        </template>
                    </vxe-column>

                    <!-- 最后更新人 -->
                    <vxe-column width="100" title="最后更新人">
                        <template v-slot="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" :src="row.update_user?.avatar"> {{ row.update_user?.name[0] }} </el-avatar>
                                {{  row.update_user?.name }}
                            </div>
                        </template>
                    </vxe-column>

                    <!-- 日期 -->
                    <vxe-column width="140" field="updated_at" title="更新日期"></vxe-column>
                    <vxe-column width="140" field="created_at" title="创建日期"></vxe-column>

                    <!-- 操作 -->
                    <vxe-column width="100" title="操作" fixed="right" align="right">
                        <template #default="{ row }">
                            <el-link type="primary" @click="$refs.drawerUpdate.Open(row.id)" class="w-margin-r-10" >编辑</el-link>
                            <el-link type="danger" @click="TableDelete(row.id)">删除</el-link>
                        </template>
                    </vxe-column>
                </vxe-table>
            </template>
        </uw-table>

        <wo-template-create ref="drawerCreate" @onChange="TableSearch"/>
        <wo-template-update ref="drawerUpdate" @onChange="TableSearch"/>
    </uw-content>
</template>

<script>
export default {
    data() {
        return {
            loading: false,

            table: [],

            // 检索
            search: {
                state: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            }
        };
    },

    mounted() {
        this.TableSearch()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 数据检索
        |--------------------------------------------------
        |
        */

        TableSearch() {
            // 切换加载状态
            this.loading = true

            this.$http.post(
                '/9api/wo-template/search',
                {
                    search: this.search,
                    order: this.order,
                    append: ['create_user', 'update_user']
                }
            ).then(rps => {
                
                // 更新数据
                this.table = rps.data

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据删除
        |--------------------------------------------------
        |
        */

        TableDelete(id) {
            this.$confirm('数据删除后不可恢复！', '请谨慎操作', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                center: true
            }).then(() => {
                this.$http.post('/9api/wo-template/delete', { id: id }).then(rps => {
                    this.TableSearch()
                })
            })
        },

        /**
        |--------------------------------------------------
        | 数据状态切换
        |--------------------------------------------------
        |
        */

        TableUpdateState(id) {
            this.$http.post('/9api/wo-template/update-state', { id: id })
        },
    },
};
</script>